import { routeToUrl, useQueryParams } from "@app-routes";
import { Helmet } from "@shared/components/helmet/helmet";
import { queryParamToString } from "@uxf/router";
import Link from "next/link";
import { FC, PropsWithChildren, ReactNode } from "react";

export interface Props {
    title: ReactNode;
    description?: ReactNode;
    pageTitle: string;
}

export const LoginLayout: FC<PropsWithChildren<Props>> = (props) => {
    const params = useQueryParams<"main-zone/login">();
    const redirectTo = queryParamToString(params.redirect);

    return (
        <div className="flex min-h-screen flex-col bg-white">
            <Helmet title={props.pageTitle} />
            <div className="flex h-[72px] items-center justify-center border-b border-lightBorder sm:justify-normal sm:px-20 xl:px-28">
                <Link href={redirectTo || routeToUrl("main-zone/index")}>
                    <img alt="VittOris" className="w-[220px]" src="/images/logo_tur.svg?v=2" />
                </Link>
            </div>
            <div className="flex grow flex-col items-center justify-center px-4 md:px-20 xl:px-28">
                <h1 className="uxf-typo-h4">{props.title}</h1>
                {props.description && (
                    <p className="mt-3 w-[392px] max-w-full text-center text-lightMedium">{props.description}</p>
                )}
                <div className="mt-8 w-[392px] max-w-full">{props.children}</div>
            </div>
        </div>
    );
};
